import { useContext } from 'react';
import Auth0Context from 'contexts/Auth0Context';
import SupportMenu from './support';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
  const { user } = useContext(Auth0Context);
  const userRoles = user?.roles || [];

  const support = SupportMenu(userRoles);

  const menuItems = {
    items: [support]
  };

  return menuItems;
};

export default MenuItems;
