import { FormattedMessage } from 'react-intl';

// assets
import { Home, DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving } from 'iconsax-react';

// icons
const icons = {
  welcomePage: Home,
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
// Function to filter menu items based on roles
const filterMenuItems = (menuItems, userRoles) => {
  return menuItems.filter((item) => {
    if (!item.roles) {
      return true; // No roles specified, item is accessible to all
    }
    return item.roles.some((role) => userRoles.includes(role));
  });
};
const SupportMenu = (userRoles) => {
  const support = {
    id: 'Main',
    title: <FormattedMessage id="others" />,
    type: 'group',
    children: filterMenuItems(
      [
        {
          id: 'welcome-page',
          title: <FormattedMessage id="welcome-page" />,
          type: 'item',
          url: '/welcome-page',
          icon: icons.welcomePage
        },
        {
          id: 'esg',
          title: <FormattedMessage id="esg" />,
          type: 'item',
          url: '/esg-page',
          icon: icons.menuLevel,
          roles: ['ESG-ROMANIA'] // Specify roles that can access this item
        },
        {
          id: 'esggreece',
          title: <FormattedMessage id="esgGreece" />,
          type: 'item',
          url: '/esg-greece',
          icon: icons.menuLevel,
          roles: ['ESG-GREECE'] // Specify roles that can access this item
        },
        // Add more menu items here with their respective roles

        {
          id: 'cerved',
          title: <FormattedMessage id="cerved" />,
          type: 'item',
          url: 'https://wwww.cervedpropertyservices.com',
          icon: icons.chipMenu,
          external: true,
          target: true
        }
      ],
      userRoles
    )
  };
  return support;
};

export default SupportMenu;
