// src/utils/route-guard/AuthGuard.js
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import Auth0Context from 'contexts/Auth0Context';

const AuthGuard = ({ children, roles }) => {
  const { isLoggedIn, user } = useContext(Auth0Context);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const containsRole = roles.some((value) => user?.roles.includes(value));
  if (!containsRole) {
    return <></>;
  }

  return children;
};

export default AuthGuard;
